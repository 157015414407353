const actions = {
  SET_STATE: 'profil/SET_STATE',
  UPDATE_BACKGROUND_INFO: 'profil/UPDATE_BACKGROUND_INFO',
  GET_NOTIFICATION_CONFIGS: 'profil/GET_NOTIFICATION_CONFIGS',
  UPDATE_NOTIFICATION_CONFIGS: 'profil/UPDATE_NOTIFICATION_CONFIGS',
  REMOVE_ACCOUNT: 'profil/REMOVE_ACCOUNT',
  LOAD_ALL_USERS: 'profil/LOAD_ALL_USERS',
  SEND_MESSAGE: 'profil/SEND_MESSAGE',
  LOAD_INBOX_MESSAGES: 'profil/LOAD_INBOX_MESSAGES',
  MAKE_MESSAGE_STARRED: 'profil/MAKE_MESSAGE_STARRED',
  REMOVE_MESSAGE_STARRED: 'profil/REMOVE_MESSAGE_STARRED',
  REMOVE_MESSAGE: 'profil/REMOVE_MESSAGE',
  LOAD_SENT_MESSAGES: 'profil/LOAD_SENT_MESSAGES',
  REMOVE_SENT_MESSAGE: 'profil/REMOVE_SENT_MESSAGE',
  MAKE_SENT_MESSAGE_STARRED: 'profil/MAKE_SENT_MESSAGE_STARRED',
  REMOVE_SENT_MESSAGE_STARRED: 'profil/REMOVE_SENT_MESSAGE_STARRED',
  LOAD_MY_GROUPS: 'profil/LOAD_MY_GROUPS',
  LOAD_INVITATION_GROUPS: 'profil/LOAD_INVITATION_GROUPS',
}

export default actions
