/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:826be816-f590-4b7b-8fb7-2a6b0f57b117",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3Gffs3SCN",
    "aws_user_pools_web_client_id": "3s0mvhuvpr9ibsfqs0n0q8du2d",
    "oauth": {
        "domain": "teachiate1-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://teachiate.com/",
        "redirectSignOut": "https://teachiate.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
