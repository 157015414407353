import React from 'react'

const FtrLogo = () => {
    return (
        <>
            <div className="ftr_logo"><a href="/"><img src="/assets/img/ftr-logo.png" alt="Footer Logo"/></a></div>
        </>
    )
}

export default FtrLogo;