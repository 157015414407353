import React from 'react'

const FtrDesc = () => {
    return (
        <>
            <div className="ftr_des">
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.</p>
            </div>
        </>
    )
}

export default FtrDesc;