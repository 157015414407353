import React from 'react'

const FtrCopyrightText = () => {
    return (
        <>
            <div className="copyright"><strong>Teachiate</strong> @ All right reserve 2020.</div>
        </>
    )
}

export default FtrCopyrightText;