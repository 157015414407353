const actions = {
    SET_STATE: 'SET_STATE',
    LOAD_SCHOOL_OPENING_UPDATES: 'LOAD_SCHOOL_OPENING_UPDATES',
    LOAD_COMMUNITY_FEEDS: 'LOAD_COMMUNITY_FEEDS',
    NEW_COMMENT: 'NEW_COMMENT',
    REPLY_COMMENT: 'REPLY_COMMENT',
    REPLY_COMMENT_SCHOOL: 'REPLY_COMMENT_SCHOOL',
    LIKE_FEED_POST: 'LIKE_FEED_POST',
    LIKE_COMMUNITY_POST: 'LIKE_COMMUNITY_POST',
  }
  
  export default actions
  